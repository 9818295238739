import React from 'react'
import './infoCard.scss'

function InfoCard({ information }) {
  return (
    <>
      <div className='card'>
        <h3 className='card-title'>{information.title}</h3>
        <p className='card-text'>{information.text}</p>
      </div>
    </>
   
  )
}

export default InfoCard