import React from 'react'
import homePageCopy from '../../../assets/content/Home/home';
import HowDoesItWork from '../../how-does-it-work/HowDoesItWork';
import InfoCard from '../../info-card/infoCard';
import './Home.scss';
import bannerImg from '../../../assets/images/Flekscharging_banner.jpg';
import serviceExplained from '../../../assets/images/Flekscharging_service_explained.jpg';

function Home() {
  const cards = homePageCopy.cardInfo
  const information = homePageCopy.informationBanner 

  return (
    <div className='home'>


      {/* Banner */}
      <div className='banner-container'>

        
        <div className='banner-img'>
          <h1>We invest in an easy to deploy<br></br>
            EV charging solution<br></br>
            at your location</h1>
          <img src={bannerImg} alt="banner img"/>
        </div>

      </div>


      {/* Business mission statement */}
      <div className='mission-statement-container'>

        <p>
          State of the art EV charging solutions utilising renewable energy for the benefit of all your tenants, customers, and vistors of your loctions
        </p>

        <div className='cards-container'>
          <div className='card-container'>
            {cards.map((card, index) => (
              <InfoCard key={index} information={card} />
            ))}
          </div>
        </div>

      </div>
      
      
      {/* Markets */}
      <div className='markets-container'>

        <h2>
          Every location is different but we have a clear focus, so we act within our sweet spot:
        </h2>

        <div className='information-banner'>
          <div className='information-banner-wide-screen'>
            <div className='information-container'>
              {information.map((info, index) => (
                <div key={index}>
                  <h3>{info.title}</h3>
                  <p>{info.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>


      {/* Service offer */}
      <div className='service-offer'>
        
        <div className='service-offer-container'>
          <h3>
            We value fleksibillity to the max and therefor offer a <span>standard solution</span> optimized for your location
          </h3>

          <div className='service-img'>
            <img src={serviceExplained} alt="service explained" />
          </div>

          <div className='service-offer-list-container'>
            <h4>
              We deliver the following levels that allow for cross selling
            </h4>
            <ul>
              <li>Simple Flekscharging : AC charger, installed and operated</li>
              <li>Fast Flekscharging : AC+DC charger, installed and operated</li>
              <li>Complete Flekscharging : EV (AC and DC), solar carport, installed and operated, PV to cover local generation</li>
            </ul>
          </div>

          <div className='service-offer-bespoke-container'>
            <h4>Bespoke flekscharging:</h4>
            <p>
              Covering various options e.g. EV charging, solar carports, PV, H2O, coffee, energy & mobility hubs installed and operated.
            </p>
          </div>
        </div>
        

      </div>


      {/* How does it work component */}
      <HowDoesItWork />


      {/* About us */}
      <div className='about-us'>

        <div className='about-us-profile'>
          <div className='about-us-pic pic1'>
            <div>
              <h4>Rard</h4>
              <p>Rard@flekscharging.nl</p>
            </div>
          </div>
          
        </div>

        <div className='about-us-profile'>
          <div className='about-us-pic pic2'>
            <div>
              <h4>Wessel Jan</h4>
              <p>Wesseljan@flekscharging.nl</p>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Home