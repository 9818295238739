import React, {useState, useRef} from 'react'
import emailjs from '@emailjs/browser';
import './Contact.scss'

function Contact() {
    const form = useRef();
    const [contact, setContact] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [contentError, setContentError] = useState(false);

    function sendEmail(e) {
        e.preventDefault();

        if (!form.current[0].value || !form.current[1].value || !form.current[2].value) {
            setContentError(true);
            return;
        }

        var templateParams = {
            from_name: form.current[0].value,
            reply_to: form.current[1].value,
            message: form.current[2].value,
        };

        emailjs.send('service_tpvufy2', 'template_jqkahdg', templateParams, 'SjCtIIJAqreO7vCFx')
        .then(function(response) {
           setShowSuccess(true);
           setTimeout(() => {
            setShowSuccess(false);
            setContact(false);
          }, 2000);
        }, function(error) {
           setShowError(true);
        });
    }

    return (
        <div className='contact'>
            <button className='contact-btn' onClick={() => setContact(!contact)}>
                Contact Us
            </button>
            
            {/* Contact modal */}
            { contact &&
                <div className='contact-form-background'>
                    <div className='contact-modal'>
                        <div className='contact-form-cancel'>
                            <button className='no-btn' onClick={() => setContact(!contact)}>X</button>
                        </div>
                        
                        <form className='contact-form' ref={form} onSubmit={sendEmail}>

                            <div className='form-input-container'>
                                <label>Name *</label>
                                <input type="text" name="from_name" />
                            </div>

                            <div className='form-input-container'>
                                <label>Email *</label>
                                <input type="text" name="reply_to" />
                            </div>

                            <div className='form-input-container'>
                                <label>Message *</label>
                                <textarea name="message" />
                            </div>
                            
                            <div className='form-input-container'>
                                <input className='btn' type="submit" value="Send" />
                                {showSuccess ?? <button className='btn'>Success</button>} 
                                {showError ?? <button className='btn'>Error</button>} 
                            </div>
                            
                        </form>

                        {showSuccess &&
                            <div className='contact-form-message success'><p>Success!</p></div>
                        }

                        {showError &&
                            // eslint-disable-next-line
                            <div className='contact-form-message error'><p>Error, email has not been send. Try <a href='mailto:info@flekscharging.nl'>click me</a>.</p><a onClick={() => setShowError(false)}>X</a></div>
                        }

                        {contentError &&
                            <div className='contact-form-message error'><p>Fill in all required fields!</p><button className='no-btn' onClick={() => setContentError(false)}>X</button></div>
                        }

                    </div>
                </div>
            } 
        </div>
    )
}

export default Contact