const homePageCopy = {
    cardInfo: [
        {
            title: 'We invest',
            text: 'We give you access to EV charging locations with full flexibillity'
        },
        {
            title: 'Our focus',
            text: 'We focus on locations that expect an increase of EV charging activites the coming years'
        },
        {
            title: 'Your gain',
            text: 'All ESG benefits are for the benefit of the property owner'
        },
        {
            title: 'User benefits',
            text: 'All tenants, visitors, customers, and more are now able to charge at your location'
        }
    ],
    informationBanner: [
        {
            title: 'Cars',
            text: '(not transport)'
        },
        {
            title: 'B2B',
            text: '(not residential)'
        },
        {
            title: 'Long term relationship',
            text: '(not transactional)'
        },
        {
            title: 'From 4+ charging facilities per location',
            text: '(to keep operational costs per facility as low as possible)'
        }
    ]
}

export default homePageCopy;