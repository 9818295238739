import React from 'react'
import './Footer.scss'

function Footer() {
  return (
    <>
        <div className='footer'>
            <div className='footer-container'>
                <h1 className='footer-logo'>Flekscharging</h1>
                <hr></hr>

                <div className='footer-links'>
                  <div><p>©2022 Flekscharging BV. All rights reserved</p></div>

                  <div className='footer-policies'>
                    <div><p>Cookie policy</p></div>
                    <div><p>Privacy policy</p></div>
                    <div><p>Terms & conditions</p></div>
                  </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer