import React from 'react'
import Contact from '../contact/Contact'
import './HowDoesItWork.scss'

function HowDoesItWork() {
  return (
    <div>
        <div className='how-it-works'>
          <div className='how-it-works-container'>
              <h2>
                We invest in an optimized EV charging solution put in place for your benefit,<br></br>
                so no upfront CAPEX constraints for you or your tenants.
              </h2>
          </div>
        </div>

        <div className='step-by-step'>
            <h2>How does it work?</h2>

            <div className='step-count-container'>

              <div className='step-count-sides step-count-left'>
                <div className='step-count-side-cell'>
                  <p>
                    Once we receive your request we will visit your location to see how flexible your location and our EV charging solutions can be deployed.
                  </p>
                </div>
                <div className='step-count-side-cell'></div>
                <div className='step-count-side-cell'>
                  <p>
                    We will show you what our flexible solutions can bring you and how this can benefit you and your tenants, customers, visitors.
                  </p>
                </div>
                <div className='step-count-side-cell'></div>
                <div className='step-count-side-cell'>
                  <p>
                    After 12-18 months we evaluate the utilization of the location.
                  </p>
                </div>
                <div className='step-count-side-cell'></div>
              </div>

              <div className='step-count-middle'>
                <div className='circle'>1</div>
                <div className='vertical-dash'></div>
                <div className='circle'>2</div>
                <div className='vertical-dash'></div>
                <div className='circle'>3</div>
                <div className='vertical-dash'></div>
                <div className='circle'>4</div>
                <div className='vertical-dash'></div>
                <div className='circle'>5</div>
                <div className='vertical-dash'></div>
                <div className='circle'>6</div>
              </div>

              <div className='step-count-sides  step-count-right'>
                <div className='step-count-side-cell'></div>
                <div className='step-count-side-cell'>
                  <p>
                    We provide an indicative flekssolution within 4 weeks upon receipt.
                  </p>
                </div>
                <div className='step-count-side-cell'></div>
                <div className='step-count-side-cell'>
                  <p>
                    Once agreed, we will install and liaise with you how we can expancd the EV charging solutions over time.
                  </p>
                </div>
                <div className='step-count-side-cell'></div>
                <div className='step-count-side-cell'>
                  <p>
                    If possible we invest further to expand the EV charging solutions and look at compensation of the property owner.
                  </p>
                </div>
              </div>

            </div>

            <h3>
              Curious to see how our fleksibile solutions can benefit you and your customers?
            </h3>

            <Contact />

        </div>
        
      </div>
  )
}

export default HowDoesItWork