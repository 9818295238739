import React from 'react'
import './Navbar.scss'

function Navbar() {
  return (
    <>
        <nav className='navbar'>
            <div className="navbar-container">
              <div className='navbar-logo'>
                <h1 className='logo'>flekscharging</h1>
              </div>

              {/*
              <div className='navbar-links'>
                <Link className='nav-link' to='/' exact>
                  <p>home</p>
                </Link>
                <Link className='nav-link' to='/about'>
                  <p>about</p>
                </Link>
                <Link className='nav-link' to='/contact'>
                  <p>contact</p>
                </Link>
              </div>
              */}

            </div>
        </nav>
    </>
  )
}

export default Navbar