import './App.scss'
import { Routes, Route } from 'react-router-dom'

import Home from './components/pages/Home/Home'

import Navbar from '../src/components/navbar/Navbar'
import Footer from './components/footer/Footer'

function App() {
  return (
    <>
      <div className='app'>
        <Navbar />
        <div className='app-body'>
          <Routes>
            <Route path='/' element={<Home />}></Route>
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
